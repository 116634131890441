import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

// Store
import { userProfileState } from '../../store/atoms';

// Hooks
import { useI18N } from '../../i18n/useI18N';
import { useToaster } from '../../hooks/useToast';

// Utils
import { convertMinuteToEstimationString, convertEstimationStringToMinute } from '../../utils/timeUtils';
import getDateModal from '../../utils/getDateModal';
import getDate from '../../utils/getDate';

// Component
import Button from '../Button';
import InputControl from '../InputControl';
import Modal from '../Modal';
import {
  IconBin,
  IconEdit,
  IconPlus,
  IconChevronUp,
  IconClock,
} from '../Icons';
import UserField from '../UserField';

// Style
import './TaskLoggedTime.scss';

const TaskLoggedTime = (props) => {
  const {
    data,
    createTimeLogMutation,
    updateTimeLogMutation,
    deleteTimeLogMutation,
    boardTeam,
    isModalMode,
    openTaskPage,
    isMobile,
    closeSectionLoggedTimeMobile,
  } = props;

  // Global State
  const userProfile = useRecoilValue(userProfileState);

  // Local State
  const [isOpenModalAddTime, setIsOpenModalAddTime] = useState(false);
  const [isOpenModalDeleteLogTime, setIsOpenModalDeleteLogTime] = useState(false);
  const [deleteTimeRecord, setDeleteTimeRecord] = useState(null);
  const { showToastError } = useToaster();

  const {
    timeLogs = [],
    id: taskId,
  } = data;

  const timeLogsSorted = [...timeLogs].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });

  const I18N = useI18N();

  const totalLogTime = timeLogsSorted.reduce((sum, current) => sum + current.value, 0);

  const defaultTimeRecord = {
    date: getDateModal(),
    type: 'MINUTES',
    value: '',
    taskId: data.id,
    userId: userProfile.id,
  };

  const [timeRecord, setTimeRecord] = useState(defaultTimeRecord);

  // Logic ModalAddTime
  const openModalAddTime = () => {
    setIsOpenModalAddTime(true);
  };

  const closeModalAddTime = () => {
    setTimeRecord(defaultTimeRecord);
    setIsOpenModalAddTime(false);
  };

  const openModalAddTimeForEdit = (record) => {
    const timeLogValue = convertMinuteToEstimationString(record.value);
    setTimeRecord({ ...record, value: timeLogValue });
    setIsOpenModalAddTime(true);
  };

  const handleChangeDate = (e) => {
    const updatedTimeRecord = {
      ...timeRecord,
      date: e.currentTarget.value,
    };
    setTimeRecord(updatedTimeRecord);
  };

  const handleChangeTime = (e) => {
    const updatedTimeRecord = {
      ...timeRecord,
      value: e.currentTarget.value,
    };
    setTimeRecord(updatedTimeRecord);
  };

  const validateTimeData = (value) => {
    const myRegExp = /^(\d{1,2}d\s*)?(\d{1,2}h\s*)?(\d{1,2}m\s*)?$/;
    return myRegExp.test(value);
  };

  const updateLoggedTime = () => {
    if (!validateTimeData(timeRecord.value) || !timeRecord.value) {
      return showToastError(I18N.INCORRECT_DATA_FORMAT);
    }

    const minute = convertEstimationStringToMinute(timeRecord.value);

    const updatedTimeRecord = {
      ...timeRecord,
      value: minute,
    };

    if (timeRecord.id) {
      return updateTimeLogMutation(updatedTimeRecord, taskId);
    }

    return createTimeLogMutation(updatedTimeRecord);
  };

  const getDateValue = (date) => {
    if (date === defaultTimeRecord.date) {
      return date;
    }
    return getDateModal(date);
  };

  const acceptAddTimeHandler = () => {
    updateLoggedTime();
    closeModalAddTime();
  };

  const cancelAddTimeHandler = () => {
    closeModalAddTime();
  };

  // Logic ModalDeleteTimeLog
  const openModalDeleteTimeLog = (record) => {
    setDeleteTimeRecord(record);
    setIsOpenModalDeleteLogTime(true);
  };

  const closeModalDeleteTimeLog = () => {
    setTimeRecord(defaultTimeRecord);
    setIsOpenModalDeleteLogTime(false);
  };

  const acceptDeleteTimeLogHandler = () => {
    deleteTimeLogMutation(deleteTimeRecord);
    closeModalDeleteTimeLog();
  };

  const cancelDeleteTimeLogHandler = () => {
    closeModalDeleteTimeLog();
  };

  // Logic user logged the time
  const authorLogTime = (record) => {
    const author = boardTeam.find((user) => user.id === record.userId);
    return author;
  };

  return (
    <div className="c-task-logged-time">
      {isModalMode ? (
        <div className="c-task-logged-time__isModalMode">
          <div className="c-task-logged-time__isModalMode__header">
            <div className="c-task__title">
              {I18N.LOGGED_TIME}
            </div>
            <Button
              text={I18N.ADD}
              iconLeft={<IconPlus />}
              size="xs"
              onClick={openModalAddTime}
            />
          </div>
          <div className="c-task-logged-time__isModalMode__time-log">
            <span className="c-task-logged-time__isModalMode__time-log__title">
              {I18N.TOTAL}
            </span>
            {timeLogsSorted.length > 0 ? (
              <span className="c-task-logged-time__isModalMode__time-log__total">
                {convertMinuteToEstimationString(totalLogTime)}
              </span>
            ) : (
              <span className="c-task-logged-time__isModalMode__time-log__empty-state ">
                {I18N.NO_LOGGED_TIME}
              </span>
            )}
            <span
              className="c-task-logged-time__isModalMode__view"
              onClick={openTaskPage}
            >
              {I18N.VIEW_ALL}
            </span>
          </div>
        </div>
      ) : (
        <div className={!isMobile ? 'c-task-logged-time__full-screen' : 'c-task-logged-time__full-screen mobile'}>
          <div className="c-task-logged-time__full-screen__header">
            <div className="c-task-logged-time__full-screen__header__title">
              {!isMobile ? (
                <>
                  <div className="c-task__title">
                    {I18N.LOGGED_TIME}
                  </div>
                  <Button
                    text={I18N.ADD}
                    iconLeft={<IconPlus />}
                    size="xs"
                    onClick={openModalAddTime}
                  />
                </>
              ) : (
                <>
                  <div className="c-task__title">
                    {I18N.TIME}
                  </div>
                  <IconPlus
                    size="sm"
                    onClick={openModalAddTime}
                  />
                </>
              )}
            </div>
            {isMobile && (
              <IconChevronUp
                size="sm"
                onClick={closeSectionLoggedTimeMobile}
              />
            )}
          </div>
          {timeLogsSorted.length > 0 ? (
            <div className="c-task-logged-time__full-screen__time-log">
              {!isMobile && (
                <p className="c-task-logged-time__full-screen__time-log__header">
                  <span>{I18N.USERNAME}</span>
                  <span>{I18N.DATE}</span>
                  <span>{I18N.TIME}</span>
                </p>
              )}
              <ul className="c-task-logged-time__full-screen__time-log__list">
                {timeLogsSorted.map((record) => (
                  <li
                    key={record.id ?? 'pending'}
                    className="c-task-logged-time__full-screen__time-log__list__item"
                  >
                    <UserField
                      user={authorLogTime(record)}
                      size="sm"
                    />
                    <span>
                      {getDate(record.date)}
                    </span>
                    <span>
                      {convertMinuteToEstimationString(record.value)}
                    </span>
                    <IconEdit
                      size="xs"
                      onClick={() => openModalAddTimeForEdit(record)}
                    />
                    <IconBin
                      size="xs"
                      onClick={() => openModalDeleteTimeLog(record)}
                    />
                  </li>
                ))}
              </ul>
              <p className="c-task-logged-time__full-screen__time-log__footer">
                {isMobile && <IconClock size="sm" />}
                <span>{I18N.TOTAL}</span>
                {!isMobile && <span />}
                <span className="c-task-logged-time__full-screen__time-log__footer__total">{convertMinuteToEstimationString(totalLogTime)}</span>
                {isMobile && <span />}
              </p>
            </div>
          ) : (
            <p className="c-task-logged-time__full-screen__empty-state ">
              {I18N.NO_LOGGED_TIME}
            </p>
          )}
        </div>
      )}
      <Modal
        isVisible={isOpenModalDeleteLogTime}
        onClose={closeModalDeleteTimeLog}
        title={I18N.DELETE_LOGGED_TIME}
        content={I18N.PLEASE_CONFIRM_DELETE_ACTION}
        type="WARNING"
        btnAcceptText={I18N.DELETE_TIME}
        btnAcceptHandler={acceptDeleteTimeLogHandler}
        btnCancelHandler={cancelDeleteTimeLogHandler}
        isMobile={isMobile}
        className="modal-task-logged-time"
      />
      <Modal
        title={timeRecord?.id ? I18N.EDIT_TIME : I18N.ADD_TIME}
        isVisible={isOpenModalAddTime}
        onClose={closeModalAddTime}
        btnAcceptText={timeRecord?.id ? I18N.EDIT_TIME : I18N.ADD_TIME}
        btnAcceptHandler={acceptAddTimeHandler}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={cancelAddTimeHandler}
        isMobile={isMobile}
        className="modal-task-logged-time"
      >
        {timeRecord && (
          <>
            <InputControl
              label={I18N.DATE}
              type="date"
              name="logDate"
              value={getDateValue(timeRecord.date)}
              onChange={handleChangeDate}
            />
            <InputControl
              label={I18N.TIME}
              type="text"
              name="logTime"
              value={timeRecord.value}
              onChange={handleChangeTime}
              placeholder={I18N.ENTER_TIME}
              suggestion={`${I18N.EXAMPLE}: 1d 2h 15m`}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default TaskLoggedTime;
