export const PAGE = {
  HOME: {
    PATH: '/',
  },
  ABOUT: {
    PATH: 'about',
    IS_PUBLIC: true,
  },
  SUPPORT: {
    PATH: 'support',
    IS_PUBLIC: true,
  },
  AUTH: {
    PATH: 'auth',
    IS_PUBLIC: true,
  },
  USER_PROFILE: {
    PATH: 'user/:userId',
  },
  BOARD: {
    PATH: 'board/:boardId',
  },
  TASK: {
    PATH: 'board/:boardId/task/:index',
    // PATH_ALT: 'task/:index',
  },
  BACKLOG: {
    PATH: 'backlog/project/:projectId',
  },
  TASK_LIST: {
    PATH: 'task-list',
  },
  // SETTINGS: {
  //   PATH: 'app/settings',
  // },
  NOT_FOUND: {
    PATH: '*',
    IS_PUBLIC: true,
  },
  NOT_ACCESS: {
    PATH: 'not-access',
    IS_PUBLIC: true,
  },
  PRIVACY_POLICY: {
    PATH: 'app/privacy-policy',
    IS_PUBLIC: true,
  },
  RESTORE_USER: {
    PATH: 'user/restore/:email/:tempPwd',
  },
  FAQ: {
    PATH: 'faq',
    IS_PUBLIC: true,
  },
};

export const DEFAULT_TASK_STATUS_ID = 0;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LANGUAGES = ['en', 'uk', 'ru', 'fr', 'de', 'es'];

export const PRIORITY = {
  high: 'high',
  medium: 'medium',
  low: 'low',
};

export const AWS = {
  S3: {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_BUCKET_DIR_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
    s3Url: 'https://ld-kanban-dev.s3.amazonaws.com/',
  },
};

export const POSITION = {
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
};

export const COLOR_PALETTE_DEFAULT = [
  '#2374AB',
  '#04FB84',
  '#4DCCBD',
  '#EDDA74',
  '#FF8484',
  '#8E35EF',
  '#7FFFD4',
  '#F660AB',
  '#FA4D4D',
];

export const COLOR_USER_LIST_DEFAULT = [
  '#D9D9D9',
  '#FCB6B6',
  '#4DCCBD',
  '#BCE7FF',
  '#9EF380',
  '#F2A1F9',
  '#7FFFD4',
  '#F660AB',
  '#FA4D4D',
];

export const AUTH_FORM_TYPE = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  RESTORE: 'RESTORE',
};

export const AUTH_STATUS = {
  FAILED: 'FAILED',
  LOADING: 'LOADING',
  NOT_AUTH: 'NOT_AUTH',
  SUCCESS: 'SUCCESS',
};

export const STATUS_CARD = {
  COLOR_MARK_DEFAULT: '#2374AB',
};

export const COLOR_THEME = {
  light: 'light',
  dark: 'dark',
};

export const BOARD_DEFAULT = {
  name: 'Board title',
  description: null,
  ownerId: null,
  colorMark: null,
  statuses: [],
  tasks: null,
  users: null,
  taskIndexPrefix: null,
  usersCount: 1,
};

export const EXPIRE_DATE_TIMER_RESTORE_PWD = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 7,
};

export const defaultToasterTimeout = 1500;

export const NOTIFICATION_TYPE = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export const API_CONTROLLER = {
  USER: 'users',
  BOARD: 'boards',
  TASK: 'task',
  TASK_STATUS: 'task-status',
  TIME_LOG: 'time-logs',
};

export const DELAY_TIME = 10000;

export const SYNC_STATE = {
  DISABLE: 'DISABLE',
  ENABLE: 'ENABLE',
};

export const BOARD_PROPERTY = {
  INFO: 'description',
  PREFIX: 'taskIndexPrefix',
  COLOR_MARK: 'colorMark',
};

export const BOARD_TYPE = {
  FAVORITE: 'favorite',
  PRIVATE: 'private',
  SHARED: 'shared',
  ARCHIVE: 'archive',
};

export const BOARD_SORT_TYPE = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  CUSTOM: 'CUSTOM',
};

export const CONTACT_FORM_TYPE = {
  CONTACT: 'CONTACT',
  SUPPORT: 'SUPPORT',
};

export const DEFAULT_BOARD_SORT_TYPE = BOARD_SORT_TYPE.CREATED;

export const MOBILE_BREAKPOINT = 768;

export const CONTACT_EMAIL = 'contact@arela.io';
