import React from 'react';

// Components
import AuthFormSocialButtons from '../AuthFormSocialButtons';
import Paragraph from '../Paragraph';
import InputControl from '../InputControl';
import CheckboxControl from '../CheckboxControl';
import Button from '../Button';
import { IconQuestionCircle } from '../Icons';
import Heading from '../Heading';

const AuthFormLogin = (props) => {
  const {
    I18N,
    activeBtn,
    changePwdVisibility,
    formData,
    formError,
    handleBlur,
    handleChange,
    handleClickForgotPwd,
    handleClickRegister,
    handleFocus,
    handleSubmit,
    isMobile,
    pwdVisibility,
    resetInput,
  } = props;

  const getPasswordValidation = () => (formError.password === '' && formData.password !== '');

  return (
    <div className="c-auth-form__view login">

      <AuthFormSocialButtons I18N={I18N} />
      <Heading text={I18N.OR_UC} type="secondary" />
      <Paragraph text={I18N.OR_SIGN_IN_WITH_EMAIL} />

      <form className="c-auth-form__form">
        <div className="c-auth-form__fieldset">
          <InputControl
            label={I18N.EMAIL}
            type="text"
            name="email"
            placeholder="Enter email"
            value={formData.email || ''}
            onChange={handleChange}
            errorMessage={formError.email}
            isValid={formError.email === '' && formData.email !== ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onReset={isMobile ? () => resetInput('email') : null}
            required
          />

          <InputControl
            label={I18N.PASSWORD}
            type="password"
            name="password"
            placeholder="Enter password"
            value={formData.password || ''}
            onChange={handleChange}
            errorMessage={formError.password}
            isValid={getPasswordValidation()}
            onBlur={handleBlur}
            onFocus={handleFocus}
            required
            showVisibility
            showPwd={pwdVisibility}
            changePwdVisibility={changePwdVisibility}
          />

          {isMobile && (
            <span
              className="c-auth-form__restore"
              onClick={handleClickForgotPwd}
            >
              {I18N.FORGOT_YOUR_PASSWORD}
            </span>
          )}
        </div>

        <div className="c-auth-form__actions">
          <div className="c-auth-form__clarification">
            <CheckboxControl
              name="remember"
              description={I18N.REMEMBER_ME}
              value={formData.remember}
              onChange={handleChange}
            />

            {!isMobile && (
              <div
                className="c-auth-form__restore"
                onClick={handleClickForgotPwd}
              >
                <IconQuestionCircle />
                <span className="c-auth-form__restore-prompt">
                  {I18N.FORGOT_PASSWORD}
                </span>
              </div>
            )}
          </div>

          <Button
            text={I18N.SIGN_IN}
            onClick={handleSubmit}
            size="xl"
            type="brand"
            disabled={!activeBtn}
          />
        </div>

        {isMobile && (
          <div className="c-auth-form__suggestion">
            <span className="c-auth-form__suggestion__text">
              {I18N.DONT_HAVE_AN_ACCOUNT}
            </span>
            <span
              className="c-auth-form__suggestion__link"
              onClick={handleClickRegister}
            >
              {I18N.REGISTER_HERE}
            </span>
          </div>
        )}
      </form>
    </div>
  );
};

export default AuthFormLogin;
