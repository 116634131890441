import React from 'react';

// Constants
import {
  PAGE,
} from '../../config/config';

// Components
import AuthFormSocialButtons from '../AuthFormSocialButtons';
import Button from '../Button';
import Heading from '../Heading';
import CheckboxControl from '../CheckboxControl';
import InputControl from '../InputControl';
import Paragraph from '../Paragraph';

const AuthFormRegister = (props) => {
  const {
    I18N,
    activeBtn,
    changeConfirmPwdVisibility,
    changePwdVisibility,
    confirmPwdVisibility,
    formData,
    formError,
    handleBlur,
    handleChange,
    handleClickLogin,
    handleClickPrivacyPolicy,
    handleFocus,
    handleSubmit,
    isMobile,
    pwdVisibility,
    resetInput,
  } = props;

  const getPasswordValidation = () => (formError.password === '' && formData.password !== '');

  return (
    <div className="c-auth-form__view register">

      <AuthFormSocialButtons I18N={I18N} />
      <Heading text={I18N.OR_UC} type="secondary" />
      <Paragraph text={I18N.OR_REGISTER_WITH_EMAIL} />

      <form className="c-auth-form__form">
        <div className="c-auth-form__fieldset">
          <InputControl
            label={I18N.EMAIL}
            type="text"
            name="email"
            placeholder="Enter email"
            value={formData.email || ''}
            onChange={handleChange}
            errorMessage={formError.email}
            isValid={formError.email === '' && formData.email !== ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onReset={isMobile ? () => resetInput('email') : null}
            required
          />

          <InputControl
            label={I18N.PASSWORD}
            type="password"
            name="password"
            placeholder="Enter password"
            value={formData.password || ''}
            onChange={handleChange}
            errorMessage={formError.password}
            isValid={getPasswordValidation()}
            suggestion={I18N.PASSWORD_SUGGESTION}
            onBlur={handleBlur}
            onFocus={handleFocus}
            required
            showVisibility
            showPwd={pwdVisibility}
            changePwdVisibility={changePwdVisibility}
          />

          <InputControl
            label={I18N.CONFIRM_PASSWORD}
            type="password"
            name="confirmPassword"
            placeholder="Enter password"
            value={formData.confirmPassword || ''}
            onChange={handleChange}
            errorMessage={formError.confirmPassword}
            isValid={formError.confirmPassword === '' && formData.confirmPassword !== ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            required
            showPwd={!isMobile && confirmPwdVisibility}
            changePwdVisibility={!isMobile && changeConfirmPwdVisibility}
            showVisibility
          />
        </div>

        <div className="c-auth-form__actions">
          <div className="c-auth-form__agreement">
            <CheckboxControl
              name="agreement"
              value={formData.agreement}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={formError.agreement}
              isValid={formData.agreement !== false}
              onFocus={handleFocus}
            >
              {I18N.AGREEMENT}
              <a
                className="c-auth-form__link"
                href={PAGE.PRIVACY_POLICY.PATH}
                onClick={handleClickPrivacyPolicy}
              >
                {I18N.PRIVACY_POLICY}
              </a>
            </CheckboxControl>
          </div>

          <Button
            text={I18N.REGISTER}
            onClick={handleSubmit}
            size="xl"
            type="brand"
            disabled={!activeBtn}
          />

          {isMobile && (
            <div className="c-auth-form__suggestion">
              <span className="c-auth-form__suggestion__text">
                {I18N.ALREADY_HAVE_AN_ACCOUNT}
              </span>
              <span
                className="c-auth-form__suggestion__link"
                onClick={handleClickLogin}
              >
                {I18N.SIGN_IN_HERE}
              </span>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default AuthFormRegister;
