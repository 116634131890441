/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Validator } from 'livr';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Utils
import generateTimerTime from '../../utils/generateTimerTime';

// Store
import { sessionState } from '../../store/atoms';
import { filterAuthErrorState } from '../../store/selector';
import useUserProfileMutations from '../../store/mutations/useUserProfileMutations';

// Components
import AuthFormGreeting from '../AuthFormGreeting';
import AuthFormNav from '../AuthFormNav/AuthFormNav';
import AuthFormLogin from '../AuthFormLogin/AuthFormLogin';
import AuthFormRegister from '../AuthFormRegister';
import AuthFormRestore from '../AuthFormRestore';
import LayoutContext from '../../context/LayoutContext';
import Loader from '../Loader';

// Constants
import {
  AUTH_FORM_TYPE,
  AUTH_STATUS,
  EXPIRE_DATE_TIMER_RESTORE_PWD,
  PAGE,
} from '../../config/config';

// Styles
import './AuthForm.scss';

const AuthForm = (props) => {
  const { authFormType } = props;

  const defaultFormData = {
    email: null,
    password: null,
    confirmPassword: null,
    remember: true,
    agreement: true,
  };

  const defaultFormError = {
    email: null,
    password: null,
    confirmPassword: null,
    agreement: null,
    hasError: false,
  };

  const I18N = useI18N();

  const authErrorUser = useRecoilValue(filterAuthErrorState);

  // State
  const [session, setSession] = useRecoilState(sessionState);
  const [formType, setFormType] = useState(authFormType);
  const [formData, setFormData] = useState(defaultFormData);
  const [formError, setFormError] = useState(defaultFormError);
  const [activeBtn, setActiveBtn] = useState(true);
  const [activeRestoreBtn, setActiveRestoreBtn] = useState(true);
  const [expireDateTime, setExpireDateTime] = useState(null);
  const [pwdVisibility, setPwdVisibility] = useState(false);
  const [confirmPwdVisibility, setConfirmPwdVisibility] = useState(false);

  const { isMobile } = useContext(LayoutContext);
  const navigate = useNavigate();
  const { loginUser, registerUser, restoreUserPwd } = useUserProfileMutations();

  const restoreButtonType = formType === AUTH_FORM_TYPE.RESTORE ? 'primary-inverse' : '';

  const isLoading = (
    session.authStatus === AUTH_STATUS.LOADING || session.authStatus === AUTH_STATUS.SUCCESS);

  const showAuthForm = session.authStatus !== AUTH_STATUS.LOADING
    && session.authStatus !== AUTH_STATUS.SUCCESS;

  const showMobileGreetings = isMobile && formType !== AUTH_FORM_TYPE.RESTORE;

  let authFormValidationRules = {
    email: ['required', 'email'],
  };

  if (formType === AUTH_FORM_TYPE.LOGIN) {
    authFormValidationRules = {
      ...authFormValidationRules,
      password: ['required', { min_length: 6 }],
    };
  }

  if (formType === AUTH_FORM_TYPE.REGISTER) {
    authFormValidationRules = {
      ...authFormValidationRules,
      password: ['required', { min_length: 6 }],
      confirmPassword: [
        'required',
        { equal_to_field: 'password' },
        { min_length: 6 },
      ],
      agreement: [{ eq: true }],
    };
  }

  const authFormValidator = new Validator(authFormValidationRules);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formError.hasError) return;

    if (formType === AUTH_FORM_TYPE.LOGIN) {
      setSession({
        ...session,
        authStatus: AUTH_STATUS.LOADING,
      });
      return loginUser(formData);
    }

    if (formType === AUTH_FORM_TYPE.REGISTER) {
      setSession({
        ...session,
        authStatus: AUTH_STATUS.LOADING,
      });
      return registerUser(formData);
    }
  };

  const submitRestorePwd = (event) => {
    event.preventDefault();

    if (formError.hasError) return;

    restoreUserPwd(formData.email);

    setActiveRestoreBtn(false);
    setExpireDateTime(generateTimerTime(EXPIRE_DATE_TIMER_RESTORE_PWD));
  };

  const activationRestorePwdBtn = () => {
    setActiveRestoreBtn(true);
  };

  const handleChange = (event) => {
    const {
      name, type, checked, value,
    } = event.target;
    const isCheckbox = type === 'checkbox';

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: isCheckbox ? checked : value,
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.currentTarget;
    let fieldValidator = null;
    switch (name) {
      case 'confirmPassword':
        fieldValidator = new Validator({
          [name]: authFormValidationRules[name],
          password: authFormValidationRules.password,
        });
        fieldValidator.validate({
          [name]: value,
          password: formData.password,
        });
        break;
      default:
        fieldValidator = new Validator({
          [name]: authFormValidationRules[name],
        });
        fieldValidator.validate({
          [name]: value,
        });
        break;
    }

    const errorMessages = fieldValidator.getErrors() || {};
    const errors = {};

    Object.keys(errorMessages).forEach((key) => {
      errors[key] = I18N[errorMessages[key]];
    });

    setFormError((prevState) => ({
      ...prevState,
      ...errors,
      hasError: fieldValidator.errors,
    }));
  };

  const handleFocus = (event) => {
    const eventTarget = event.currentTarget;
    const clearCurrentError = {
      ...formError,
      [eventTarget.name]: null,
    };

    setActiveRestoreBtn(true);

    if (
      formError.password !== I18N.SMALL_PASSWORD
      && formError.password !== null
    ) {
      setFormError({ ...defaultFormError });
    } else {
      setFormError(clearCurrentError);
    }

    if (formType === AUTH_FORM_TYPE.RESTORE) {
      setFormError({ ...defaultFormError });
    }
  };

  const handleAuthentication = () => {
    if (formType === AUTH_FORM_TYPE.REGISTER) {
      setSession({ ...session, hasError: null, errorRegister: null });

      setFormError({
        ...formError,
        email: authErrorUser,
        hasError: true,
      });
    }

    if (formType === AUTH_FORM_TYPE.LOGIN) {
      setSession({ ...session, hasError: null, errorLogin: null });

      setFormError({
        ...formError,
        email: true,
        password: authErrorUser,
        hasError: true,
      });
    }

    if (formType === AUTH_FORM_TYPE.RESTORE) {
      setSession({ ...session, hasError: null, restorePwd: null });

      setFormError({
        ...formError,
        email: authErrorUser,
        hasError: true,
      });
    }
  };

  const handleClickForgotPwd = () => {
    setFormType(AUTH_FORM_TYPE.RESTORE);
    setFormError({ ...defaultFormError });
    setSession({ ...session, restorePwd: null });
    setExpireDateTime(null);
    setActiveRestoreBtn(true);
  };

  const handleClickRegister = () => {
    setFormType(AUTH_FORM_TYPE.REGISTER);
    setFormError({ ...defaultFormError });
    setSession({ ...session, restorePwd: null });
    setExpireDateTime(null);
    setActiveRestoreBtn(true);
  };

  const handleClickLogin = () => {
    setFormType(AUTH_FORM_TYPE.LOGIN);
    setFormError({ ...defaultFormError });
    setSession({ ...session, restorePwd: null });
    setExpireDateTime(null);
    setActiveRestoreBtn(true);
  };

  const handleClickPrivacyPolicy = (event) => {
    event.preventDefault();
    setSession({ ...session, authFormType: null });
    navigate(`/${PAGE.PRIVACY_POLICY.PATH}`);
  };

  const handleKeyDown = (event) => {
    if (event.code === 'ArrowLeft' && event.target.nodeName !== 'INPUT') {
      handleClickLogin();
    }

    if (event.code === 'ArrowRight' && event.target.nodeName !== 'INPUT') {
      handleClickRegister();
    }

    if (
      event.code === 'Enter'
      && formType !== AUTH_FORM_TYPE.RESTORE
      && activeBtn
    ) {
      handleSubmit(event);
    }

    if (
      event.code === 'Enter'
      && formType === AUTH_FORM_TYPE.RESTORE
      && activeBtn
      && activeRestoreBtn
    ) {
      submitRestorePwd(event);
    }

    if (event.code === 'Escape' && formType === AUTH_FORM_TYPE.RESTORE) {
      handleClickLogin();
    }
  };

  const changePwdVisibility = () => {
    setPwdVisibility((prevState) => !prevState);
  };

  const changeConfirmPwdVisibility = () => {
    setConfirmPwdVisibility((prevState) => !prevState);
  };

  useEffect(() => {
    if (authErrorUser !== null && session.hasError) {
      handleAuthentication();
    }
    let isDisabled = null;
    if (formType === AUTH_FORM_TYPE.LOGIN) {
      authFormValidator.validate({
        email: formData.email,
        password: formData.password,
      });
      isDisabled = authFormValidator.getErrors();
    }

    if (formType === AUTH_FORM_TYPE.REGISTER) {
      authFormValidator.validate({
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
        agreement: formData.agreement,
      });
      isDisabled = authFormValidator.getErrors();
    }

    document.addEventListener('keydown', handleKeyDown);
    setActiveBtn(
      !formError.hasError && !authFormValidator.getErrors() && !isDisabled,
    );

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [authErrorUser, formError, formData]);

  const resetInput = (name) => {
    setFormData((prev) => ({ ...prev, [name]: '' }));
  };

  return (
    <section className="c-auth-form">
      {showAuthForm && (
        <div className="c-auth-form__container panel">
          {showMobileGreetings && (
            <AuthFormGreeting formType={formType} I18N={I18N} />
          )}

          {!isMobile && (
            <AuthFormNav
              formType={formType}
              handleClickLogin={handleClickLogin}
              handleClickRegister={handleClickRegister}
              I18N={I18N}
            />
          )}

          {formType === AUTH_FORM_TYPE.LOGIN && (
            <AuthFormLogin
              I18N={I18N}
              isMobile={isMobile}
              formError={formError}
              formData={formData}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              handleSubmit={handleSubmit}
              handleClickForgotPwd={handleClickForgotPwd}
              handleClickRegister={handleClickRegister}
              activeBtn={activeBtn}
              pwdVisibility={pwdVisibility}
              changePwdVisibility={changePwdVisibility}
              resetInput={resetInput}
            />
          )}

          {formType === AUTH_FORM_TYPE.REGISTER && (
            <AuthFormRegister
              I18N={I18N}
              activeBtn={activeBtn}
              changeConfirmPwdVisibility={changeConfirmPwdVisibility}
              confirmPwdVisibility={confirmPwdVisibility}
              formData={formData}
              formError={formError}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleClickLogin={handleClickLogin}
              handleClickPrivacyPolicy={handleClickPrivacyPolicy}
              handleFocus={handleFocus}
              handleSubmit={handleSubmit}
              isMobile={isMobile}
              resetInput={resetInput}
            />
          )}

          {formType === AUTH_FORM_TYPE.RESTORE && (
            <AuthFormRestore
              I18N={I18N}
              isMobile={isMobile}
              formData={formData}
              formError={formError}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleFocus={handleFocus}
              resetInput={resetInput}
              session={session}
              submitRestorePwd={submitRestorePwd}
              restoreButtonType={restoreButtonType}
              activeBtn={activeBtn}
              activeRestoreBtn={activeRestoreBtn}
              handleClickLogin={handleClickLogin}
              activationRestorePwdBtn={activationRestorePwdBtn}
              expireDateTime={expireDateTime}
            />
          )}

        </div>
      )}
      {isLoading && <Loader />}
    </section>
  );
};

export default AuthForm;
