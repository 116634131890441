import {
  React,
  useState,
  useRef,
} from 'react';

// Components
import Button from '../Button';
import {
  IconPlus,
  IconCrossRound,
  IconSave,
  IconChevronUp,
} from '../Icons';
import Tag from '../Tag';

// Style
import './TagList.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const TagList = (props) => {
  const {
    updateActiveTaskMutation,
    data,
    isMobile,
    closeSectionTagListMobile,
  } = props;

  const refForInput = useRef(null);

  const I18N = useI18N();

  const tagListClassName = isMobile ? 'c-tag-list mobile' : 'c-tag-list';

  // State
  const [inputValue, setInputValue] = useState(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputTags, setInputTags] = useState([]);

  const setInputActive = () => {
    setInputVisible(true);
    setTimeout(() => {
      if (refForInput.current) {
        refForInput.current.focus();
      }
    }, 0);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue !== null) {
      event.preventDefault();
      setInputTags([...inputTags, { name: inputValue }]);
      setInputValue(null);
    }
  };

  const resetInput = () => {
    setInputValue(null);
    setInputVisible(false);
    setInputTags([]);
  };

  const updateTags = () => {
    const allTags = [...inputTags, ...data.tags];

    if (inputValue !== null) {
      allTags.unshift({ name: inputValue });
    }

    const updatedTags = allTags.filter(
      (tag, index, self) => self.findIndex(
        (findTag) => findTag.name === tag.name,
      ) === index,
    );

    updateActiveTaskMutation({
      id: data.id,
      tags: [
        ...updatedTags,
      ],
    });
    resetInput();
  };

  const updateInputValue = (event) => {
    setInputValue(event.target.value);
  };

  const deleteTag = (delTag) => {
    if (!delTag.id) {
      setInputTags((existTags) => existTags.filter((tag) => tag.name !== delTag.name));
    }
    updateActiveTaskMutation({
      id: data.id,
      tags: data.tags.filter((tag) => tag.name !== delTag.name),
    });
  };

  return (
    <div className={tagListClassName}>
      <div className="c-tag-list__header">
        {!isMobile && (
          <span className="c-task__title">{I18N.TAGS}</span>
        )}
        {isMobile && (
          <div className="c-tag-list__header__title">
            <div className="c-tag-list__header__title__name">
              <span className="c-task__title">{I18N.TAGS}</span>
              <IconPlus
                size="sm"
                onClick={setInputActive}
              />
            </div>
            <IconChevronUp
              size="sm"
              onClick={closeSectionTagListMobile}
            />
          </div>
        )}
        {inputVisible && (
          <div className="c-tag-list__header__input">
            <input
              ref={refForInput}
              className="c-tag-list__header__input__field"
              type="text"
              placeholder={I18N.ENTER_NEW_TAG}
              value={inputValue || ''}
              onChange={updateInputValue}
              onKeyDown={handleInputKeyDown}
            />
            <div className="c-tag-list__header__input__option">
              <IconSave
                size="xs"
                onClick={updateTags}
              />
              <IconCrossRound
                size="xs"
                onClick={resetInput}
              />
            </div>
          </div>
        )}
        {!isMobile && (
          <Button
            className="c-tag-list__header__button"
            text={I18N.ADD}
            iconLeft={<IconPlus />}
            size="xs"
            onClick={setInputActive}
          />
        )}
      </div>
      {inputTags.length > 0 && (
        <ul className="c-tag-list__tags">
          {inputTags.map((tagItem) => (
            <li
              key={tagItem.id}
              className="c-tag-list__input-tag"
            >
              <Tag
                tag={tagItem}
              />
              <IconCrossRound
                onClick={() => deleteTag(tagItem)}
                size="xs"
              />
            </li>
          ))}
        </ul>
      )}
      <ul className="c-tag-list__list">
        {data.tags && data.tags.length ? data.tags.map((tagItem) => (
          <li
            key={tagItem.id}
            className="c-tag-list__list__tag"
          >
            <Tag tag={tagItem} />
            {!isMobile && (
              <IconCrossRound
                onClick={() => deleteTag(tagItem)}
                size="xs"
              />
            )}
            {isMobile && (
              <IconPlus
                onClick={() => deleteTag(tagItem)}
                size="xs"
              />
            )}
          </li>
        )) : (
          <p className="c-tag-list__list__empty-state">
            {I18N.NO_TAGS_ADDED}
          </p>
        )}
      </ul>
    </div>
  );
};

export default TagList;
